import { getDict } from '@/filters/fromDict'
import { formatDate } from '@/utils/auth/common'

// 合同信息
export const contractFormConfig = (content) => {
  return [
    {
      label: '工程项目',
      prop: 'projectName',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }

    },
    {
      label: '合同编号',
      prop: 'contractCode',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '合同名称',
      prop: 'contractName',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 10, xl: 6 }
    },
    {
      label: '合同金额(元)',
      prop: 'contractAmount',
      type: 'Amount',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '补充协议调整金额(元)',
      type: 'Amount',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '供应商名称',
      prop: 'gysName',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '预付款支付比例(%)',
      prop: 'advanceChargePercent',
      attrs: {
        placeholder: '请输入',
        type: 'BL'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '进度款支付比例(%)',
      prop: 'progressPercent',
      attrs: {
        placeholder: '请输入',
        type: 'BL'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '结算款支付比例(%)',
      prop: 'settlementPercent',
      attrs: {
        placeholder: '请输入',
        type: 'BL'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '发票类型',
      prop: 'invoicedType',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '纳税人身份',
      prop: 'taxpayerType',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '结算金额(元)',
      prop: 'settlementMoney',
      type: 'Amount',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    }

  ]
}
// 请款信息
export const financeFormConfig = (content) => {
  return [
    {
      label: '申请编号',
      prop: 'easNo',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '付款类型',
      prop: 'paymentTypeName',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '所属项目',
      prop: 'projectName',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '业务部门',
      prop: 'businessdepartment',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '申请人',
      prop: 'applicant',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '支付方式',
      prop: 'payType',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '乙方申请金额(元)',
      prop: '', // 'localCurrencyMoney',
      type: 'Amount',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '上期累计付款金额(元)',
      prop: 'lastTotalApply',
      type: 'Amount',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    }, {
      label: '上期累计付款比例(%)',
      prop: '', // 'lastTotalApplyProportion',
      attrs: {
        placeholder: '请输入',
        type: 'BL'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '本期累计申请金额(元)',
      prop: 'currentTotalApply',
      type: 'Amount',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    }, {
      label: '本期累计付款比例(%)',
      prop: 'paymentTotalPercent',
      attrs: {
        placeholder: '请输入',
        type: 'BL'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    }, {
      label: '确认付款金额(元)',
      prop: 'paymentApplyAmount',
      type: 'Amount',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    }, {
      label: '本次付款比例(%)',
      prop: 'actualPaymentProportion',
      attrs: {
        placeholder: '请输入',
        type: 'BL'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '币别',
      prop: 'currency',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '汇率',
      prop: 'exchangeRate',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '本币金额(元)',
      prop: 'localCurrencyMoney',
      type: 'Amount',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    }, {
      label: '本次实付金额(元)',
      prop: 'actualPayment',
      type: 'Amount',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '备注',
      prop: 'remark',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 10, xl: 6 }
    },
    {
      label: '申请日期',
      prop: 'applyDate',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '费用类型',
      prop: 'costType',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '增值税额(元)',
      prop: 'valueAddedTax',
      type: 'Amount',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '不含税金额(元)',
      prop: 'taxFreeMoney',
      type: 'Amount',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '增值税率(%)',
      prop: 'valueAddedTaxRate',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '付款接收人邮箱',
      prop: 'payeeEmail',
      attrs: {
        placeholder: '请输入'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    }
  ]
}
// 财务信息
export const propertyOrmConfig = (content) => {
  return [
    {
      label: '收款单位名称',
      prop: 'receiptCompanyName'
    },
    {
      label: '开户银行',
      prop: 'bankName'
    },
    {
      label: '银行账户',
      prop: 'bankAccountNumber'
    }
  ]
}
// 明细表格
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '项目',
      prop: 'item'
    },
    {
      label: '原合同金额(元)',
      prop: 'originalContractMoney'
    },
    {
      label: '付款比例',
      prop: 'payPercent',
      'show-overflow-tooltip': true
    },
    {
      label: '完成工程产值(元)',
      childs: [
        {
          label: '本期审核(元)',
          prop: 'currentApprovalMoney',
          minWidth: '120px',
          formatter: row => {
            return (row.currentApprovalMoney ? row.currentApprovalMoney.toLocaleString('en-US') : '--')
          }
        },
        {
          label: '上期累计(元)',
          prop: 'lastTotalMoney',
          minWidth: '120px',
          formatter: row => {
            return (row.lastTotalMoney ? row.lastTotalMoney.toLocaleString('en-US') : '--')
          }
        },
        {
          label: '本期累计(元)',
          prop: 'currentTotalMoney',
          minWidth: '120px',
          formatter: row => {
            return (row.currentTotalMoney ? row.currentTotalMoney.toLocaleString('en-US') : '--')
          }
        }
      ]
    }, {
      label: '应付金额(元)',
      childs: [
        {
          label: '本期申请(元)',
          type: 'Amount',
          prop: 'currentPayMoney',
          minWidth: '120px',
          formatter: row => {
            return (row.currentPayMoney ? row.currentPayMoney.toLocaleString('en-US') : '--')
          }
        },
        {
          label: '上期申请(元)',
          type: 'Amount',
          prop: 'lastTotalPayMoney',
          minWidth: '120px',
          formatter: row => {
            return (row.lastTotalPayMoney ? row.lastTotalPayMoney.toLocaleString('en-US') : '--')
          }
        },
        {
          label: '本期累计(元)',
          type: 'Amount',
          prop: 'currentTotalPayMoney',
          minWidth: '120px',
          formatter: row => {
            return (row.currentTotalPayMoney ? row.currentTotalPayMoney.toLocaleString('en-US') : '--')
          }
        }
      ]
    },
    {
      label: '占签约合同价比例',
      prop: 'contractPercent',
      'show-overflow-tooltip': true
    },
    {
      label: '备注',
      prop: 'remark',
      'show-overflow-tooltip': true
    }
  ]
}

// 融资单信息
export const financingConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '融资单号',
      prop: 'billNumber'
    },
    {
      label: '融资金额(元)',
      prop: 'financingAmount',
      align: 'right',
      formatter: row => {
        return row.financingAmount.toLocaleString('en-US', { minimumFractionDigits: 2 })
      }
    },
    {
      label: '款项名称',
      prop: 'paymentName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '保理公司名称',
      prop: 'factorCompanyName',
      'show-overflow-tooltip': true,
      formatter: row => {
        return row.factorCompanyName ? row.factorCompanyName : '--'
      }
    },
    {
      label: '分配时间',
      prop: 'distributeTime',
      'show-overflow-tooltip': true
    },
    {
      label: '融资进度',
      prop: 'billProgress',
      'show-overflow-tooltip': true,
      formatter: row => {
        return row.billProgress ? getDict('BILL_PROGRESS', row.billProgress) : '--'
      }
    },
    {
      label: '融资进度明细',
      prop: 'billProgressDetail',
      'show-overflow-tooltip': true,
      formatter: row => {
        return row.billProgressDetail ? getDict('BILL_PROGRESS_DETAIL', row.billProgressDetail) : '--'
      }
    },
    // {
    //   label: '付款审批完成日期',
    //   prop: 'paymentApprovedDate',
    //   width: '140px',
    //   formatter: row => {
    //     return formatDate(row.paymentApprovedDate, 'YY/MM/DD')
    //   }
    // },
    {
      label: '预计放款日期',
      prop: 'factorPrePayedDate',
      width: '110px',
      formatter: row => {
        return formatDate(row.factorPrePayedDate, 'YY/MM/DD')
      }
    },
    {
      label: '放款日期',
      prop: 'factorPayedDate',
      width: '110px',
      formatter: row => {
        return formatDate(row.factorPayedDate, 'YY/MM/DD')
      }
    },
    {
      label: '放款金额',
      prop: 'factorPayedAmount',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: row => {
        return row.factorPayedAmount ? row.factorPayedAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--'
      }
    },
    {
      label: '备注',
      prop: 'remark',
      'show-overflow-tooltip': true,
      align: 'left'
    }
  ]
}
