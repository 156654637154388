// 付款单详情内容
<template>
  <div class="payment-order-details">
    <backtitle lable="付款单信息" v-if="!keyId" />
    <el-collapse v-model="activeNames">
      <!-- 合同信息 -->
      <el-collapse-item name="1">
        <template slot="title">
          <text-title index='01' label="基本信息" />
        </template>
        <div class="box">
          <div class="tab-box">
            <form-info
              :componentList="contractform"
              :model="contractQueryParas"
              :showbor="true"
              :count='count'
            />
          </div>
        </div>
        <!-- 请款信息 -->
        <div class="box">
          <el-tabs v-model="activeName">
            <el-tab-pane label="请款信息" name="first">
              <div class="tab-box">
                <form-info
                  :componentList="financeForm"
                  :model="assetsQueryParas"
                  :showbor="true"
                  :count='count'
                />
              </div>
            </el-tab-pane>
            <el-tab-pane label="财务信息" name="second">
              <div class="tab-box">
                <form-info
                  :componentList="propertyForm"
                  :model="propertyQueryParas"
                  :showbor="true"
                  :count='count'
                />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-collapse-item>
      <!-- 明细 -->
      <el-collapse-item name="2">
        <template slot="title">
          <text-title
          index='02'
            label="明细"
            tips="（注：本期申请金额=（本期审核产值+上期累计产值）*付款比例-上期累计申请金额）"
          ></text-title>
        </template>
        <div class="box">
          <base-table
            :columns="columns"
            :showPage="false"
            :tableAttrs="{
              data: tableData,
              stripe: true,
            }"
            :is-caculate-height="false"
            ref="tableData"
            :webPage="false"
            class="order-table"
          >
            <template slot="index" slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </base-table>
        </div>
      </el-collapse-item>
      <!-- 融资单信息 -->
      <el-collapse-item name="3">
        <template slot="title">
          <text-title
          index='03'
            label="融资单信息"
          ></text-title>
        </template>
        <div class="box">
          <base-table
            :columns="financingConfig"
            :showPage="false"
            :tableAttrs="{
              data: financeData,
              stripe: true,
            }"
            :is-caculate-height="false"
            ref="financeData"
            :webPage="false"
            class="order-table"
          >
            <template slot="index" slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </base-table>
        </div>
      </el-collapse-item>
      <!-- 附件 -->
      <el-collapse-item name="4">
        <template slot="title">
          <text-title index='04' label="附件"></text-title>
        </template>
        <div class="box">
          <div class="download">
            <base-button
              label="下载全部"
              @click="downAll"
              icon="iconfont iconxiazai"
              class="down-all"
            />
          </div>
          <div class="file-box">
            <div
              class="file-item"
              v-for="(item, index) of fileData"
              :key="index"
            >
              <i class="iconfont font26" :class="addFontIcon(item.suffix)"></i>
              <div class="file-name">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.fileName"
                  placement="top-start"
                  :disabled="getwidths(item.fileName, index)"
                >
                  <div class="fileName">
                    <el-link type="primary" @click="downloadEnclosure(item)"
                      >{{ index + 1 }}.{{ item.fileName }}</el-link>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import backtitle from '@/pages/business/components/backtitle.vue'
import { downFile } from '@/utils/devUtils'
import { downAPi } from '@/api/fileApi'
import FormInfo from '@/components/packages/form-info/form-info.vue'
import {
  contractFormConfig,
  financeFormConfig,
  columnsConfig,
  propertyOrmConfig,
  financingConfig
} from './config'
import BaseTable from '@/components/common/table/base-table/base-table'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import TextTitle from '@/components/packages/text-title/text-title.vue'
import { assetinfoApi } from '@/api/assetApi'
import { formatDate } from '@/utils/auth/common'
import { getDictLists } from '@/filters/fromDict'

export default {
  name: 'PaymentOrderDetails',
  components: { BaseTable, BaseButton, TextTitle, FormInfo, backtitle },
  props: {
    keyId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      count: 0,
      queryParas: {},
      contractQueryParas: {},
      assetsQueryParas: {},
      propertyQueryParas: {},
      tableData: [],
      contractform: [],
      financeForm: [],
      propertyForm: [],
      activeName: 'first',
      fileData: [],
      // 折叠面板默认全部激活
      activeNames: ['1', '2', '3', '4'],
      financeData: []
    }
  },
  computed: {
    columns () {
      return columnsConfig(this)
    },
    financingConfig () {
      return financingConfig(this)
    }
  },
  methods: {
    // 单个文件下载
    downloadEnclosure (data) {
      downAPi
        .download({ keyId: data.fileId })
        .then((res) => {
          downFile(res)
        })
        .catch((e) => {
          this.error(e)
        })
    },
    // 是否禁用文字提示
    getwidths (name) {
      if (name) {
        const arr = name.split('')
        if (arr.length > 16) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    // 初始化数据
    initialization () {
      this.contractform = contractFormConfig(this)
      this.financeForm = financeFormConfig(this)
      this.propertyForm = propertyOrmConfig(this)
      const keyId = this.keyId || this.$route.query.keyId
      assetinfoApi.assetInfoDetail({ keyId: keyId }).then((res) => {
        if (res.success) {
          // 回显数据处理
          res.data.applyDate = formatDate(res.data.applyDate, 'YY-MM-DD')
          this.contractQueryParas = res.data.contractInfoVO ? res.data.contractInfoVO : {}
          this.propertyQueryParas = res.data?.payapplyCollectorInfoVO || {}
          this.assetsQueryParas = JSON.parse(JSON.stringify(res.data))
          // 本期累计付款比例组装参数
          if (res.data.contractInfoVO) {
            this.assetsQueryParas.paymentTotalPercent = res.data.contractInfoVO.paymentTotalPercent ? res.data.contractInfoVO.paymentTotalPercent * 100 : ''
          }
          this.assetsQueryParas.valueAddedTaxRate = this.assetsQueryParas && this.assetsQueryParas.valueAddedTaxRate ? this.assetsQueryParas.valueAddedTaxRate * 100 : ''
          this.contractQueryParas.progressPercent = this.contractQueryParas && this.contractQueryParas.progressPercent ? this.contractQueryParas.progressPercent * 100 : ''
          this.contractQueryParas.advanceChargePercent = this.contractQueryParas && this.contractQueryParas.advanceChargePercent ? this.contractQueryParas.advanceChargePercent * 100 : ''
          this.contractQueryParas.settlementPercent = this.contractQueryParas && this.contractQueryParas.settlementPercent ? this.contractQueryParas.settlementPercent * 100 : ''
          const [paymentType] = getDictLists('EAS_PAYMENT_TYPE').filter(item => item.dictId === (this.assetsQueryParas.paymentType !== undefined ? this.assetsQueryParas.paymentType.toString() : ''))
          this.assetsQueryParas.paymentTypeName = paymentType ? paymentType.dictName : ''
          this.tableData = res.data.payapplyDetailInfos
          this.financeData = res.data.payapplyFinancingInfos
          this.tableData.forEach((item) => {
            item.contractPercent = item.contractPercent ? item.contractPercent * 100 + '%' : ''
            item.payPercent = item.payPercent ? item.payPercent * 100 + '%' : ''
          })
          this.fileData = res.data.payapplyFileInfos
        }
        this.count++
      })
    },
    // 文件图标类型
    addFontIcon (suffix) {
      if (suffix) {
        const img = ['jpg', 'png', 'jpeg', 'bmp']
        const compress = ['zip', 'ara']
        const imgFlag = img.some((val) => {
          return val === suffix
        })
        const compressFlag = compress.some((val) => {
          return val === suffix
        })
        const pdfFlag = suffix === 'pdf'
        if (imgFlag) {
          return 'icontupian'
        } else if (compressFlag) {
          return 'iconyasuobao'
        } else if (pdfFlag) {
          return 'iconpdf'
        }
      }
    },
    // 批量下载
    downAll () {
      const filtKeyIds = this.fileData.map((item) => {
        return item.fileId
      })
      if (filtKeyIds.length > 0) {
        downAPi
          .batchDownload({ keyIds: filtKeyIds, fileName: '全部附件' })
          .then((res) => {
            downFile(res)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    }
  },
  created () {
    this.initialization()
    this.addFontIcon()
  }
}
</script>
<style lang="scss" src='./index.scss' scoped/>
